import * as React from "react"
import {
  wordpress,
  drupal,
  contentful,
  datocms,
  contentstack,
  sanity,
  prismic,
  shopify,
  kentico as kontent,
  strapi,
  segment as segmentio,
  storyblok,
  hubspot,
} from "../../images/plugins"

export interface CMSBadgesProps {
  platforms: string[]
}

// private API
const images: any = {
  wordpress,
  drupal,
  contentful,
  datocms,
  contentstack,
  sanity,
  prismic,
  shopify,
  kontent,
  strapi,
  segmentio,
  storyblok,
  hubspot,
}

export default function CMSBadges({ platforms }: CMSBadgesProps) {
  return (
    <div
      css={theme => ({
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: theme.space[6],
      })}
    >
      {platforms.map(cms => {
        // normalizes platform names, becase some of them could contain spaces, dots or lower/upper chars

        const key = cms
          .toLowerCase()
          .replace(` `, ``)
          .replace(`.`, ``)

        const icon = images[key]

        if (!icon) {
          return null
        }

        return (
          <div
            key={cms}
            css={{
              width: 24,
              height: 24,
            }}
          >
            {icon}
          </div>
        )
      })}
    </div>
  )
}
